import classNames from 'classnames';
import { Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  deleteDocument,
  updateDocumentsStatusDeleted,
} from '../../../redux/reducers/documentsStatusSlice';
import { deleteDocuments } from '../../../db/documentDBAction';
import { selectRows, updateLeaseDocumentDeleted } from '../../../redux/reducers/documentsDataSlice';
import { selectSelectedDocumentId, setDeSelectDocument, setDocumentDeleted, setSelectedDocument } from '../../../redux/reducers/dashboardSlice';
import { selectInboxId } from '../../../redux/reducers/metadataSlice';
import { updateParentsWithChildren } from '../../../redux/reducers/documentsSlice';

function RowContextMenu(props: any) {
  const dispatch = useAppDispatch();
  const selectedDocumentId = useAppSelector(selectSelectedDocumentId);
  const inboxId = useAppSelector(selectInboxId);
  const inboxDocuments = useAppSelector(selectRows)[inboxId];
  
  const handleDeleteDocument = () => {
    const document = props.propsFromTrigger.data;
    deleteDocuments([document.id]).then(() => {
      const checkIfOnlyChild = inboxDocuments.filter(e => e.parent === document.parent).length === 1;
      if (checkIfOnlyChild) {
        dispatch(updateParentsWithChildren(document.parent));
      }
      dispatch(deleteDocument(document.id)).then(() => {
        if (selectedDocumentId === document.id) {
          dispatch(setDeSelectDocument);
        }
        dispatch(setDocumentDeleted(true));
      });
    });
  };
  return (
    <section>
      <Item
        key='rowContextMenu'
        id='rowContextMenu'
        className={classNames('context-menu-item')}
        onClick={handleDeleteDocument}>
        <span className={classNames('menu-status-text', 'context-menu-text')}>Delete LOI</span>
      </Item>
    </section>
  );
}
export default RowContextMenu;
