import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PopupAndMenuState } from '../../common/types/SliceTypes';

const initialState: PopupAndMenuState = {
  show: false,
  isUploader: false,
  isAbstraction: false,
  isSettings: false,
  currentMenu: '',
  currentMenuTrigerrorId: '',
  isFilterDeletePopup: false,
  isTabContextMenuActive: false,
  isTabSharePopupActive: false,
  modalOffset: [0,0],
};

export const popupAndDialogSlice = createSlice({
  name: 'popup',
  initialState: initialState,
  reducers: {
    setOpen: (state) => {
      return {
        ...state,
        show: true,
      };
    },
    setClose: (state) => {
      return {
        ...state,
        show: false,
        isUploader: false,
        isAbstraction: false,
        isSettings: false,
      };
    },
    setUploader: (state) => {
      return {
        ...state,
        show: true,
        isUploader: true,
        isAbstraction: false,
        isSettings: false,
      };
    },
    setAbstraction: (state) => {
      return {
        ...state,
        show: true,
        isUploader: false,
        isAbstraction: true,
        isSettings: false,
      };
    },
    setSettings: (state, value) => {
      return {
        ...state,
        show: value.payload,
        isUploader: false,
        isAbstraction: false,
        isSettings: value.payload,
      };
    },
    setMenu: (state, value) => {
      return {
        ...state,
        currentMenu: value.payload,
      };
    },
    setMenuTriggor: (state, value) => {
      return {
        ...state,
        currentMenuTrigerrorId: value.payload,
      };
    },
    setShowFilterDeletePopup: (state, value) => {
      return {
        ...state,
        isFilterDeletePopup: value.payload,
      };
    },
    setIsTabContextMenuActive: (state, value) => {
      return {
        ...state,
        isTabContextMenuActive: value.payload,
      };
    },
    setShowTabSharePopup: (state, value) => {
      return {
        ...state,
        isTabSharePopupActive: value.payload,
      }
    },
    setModalOffset: (state, value) => {
      return {
        ...state,
        modalOffset: value.payload,
      }
    },
  },
});

export const {
  setOpen,
  setUploader,
  setAbstraction,
  setSettings,
  setClose,
  setMenu,
  setMenuTriggor,
  setShowFilterDeletePopup,
  setIsTabContextMenuActive,
  setShowTabSharePopup,
  setModalOffset,
} = popupAndDialogSlice.actions;

export const selectShow = (state: RootState) => state.popup.show;
export const selectIsSettings = (state: RootState) => state.popup.isSettings;
export const selectUploader = (state: RootState) => state.popup.isUploader;
export const selectAbstraction = (state: RootState) => state.popup.isAbstraction;
export const selectMenu = (state: RootState) => state.popup.currentMenu;
export const selectIsFilterDeletePopup = (state: RootState) => state.popup.isFilterDeletePopup;
export const selectMenuTriggor = (state: RootState) => state.popup.currentMenuTrigerrorId;
export const selecteIsTabContextMenuActive = (state: RootState) => state.popup.isTabContextMenuActive;
export const selectTabSharePopup = (state: RootState) => state.popup.isTabSharePopupActive;
export const selectModalOffset = (state: RootState) => state.popup.modalOffset;

export default popupAndDialogSlice.reducer;
