import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.scss";
import { store } from "./redux/store";
import "./assets/styles/index.css";
import { register as registerServiceWorker } from "./serviceWorkerRegistration";
import AppReadOnly from "./AppReadOnly";

export const outboxIsSupported =
  "serviceWorker" in navigator && "SyncManager" in window;

const currentURL = window.location.href;
const params = currentURL.split("?");
let isReadOnly = false;

if (currentURL && params.length > 1) {
  if (params[1])
    if (params[1].split("&")[0] === "readOnly") {
      isReadOnly = true;
    }
}

const container = document.getElementById("root")!;
const root = createRoot(container);

const providerConfig: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "apdt.us.auth0.com",
  clientId:
    process.env.REACT_APP_AUTH0_CLIENT_ID || "08TRgBGbVTjPyR2mzN3f2sWBj1nByGXZ",
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
};

root.render(
  <React.StrictMode>
    {isReadOnly ? (
      <Provider store={store}>
        <AppReadOnly />
      </Provider>
    ) : (
      <Auth0Provider {...providerConfig}>
        <Provider store={store}>
          <App />
        </Provider>
      </Auth0Provider>
    )}
  </React.StrictMode>
);

registerServiceWorker();
