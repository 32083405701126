import React, { Suspense, useEffect } from 'react';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

import { useLiveQuery } from 'dexie-react-hooks';
import _ from 'lodash';
import './App.scss';
import { useAxiosLoader } from './api/axios';
import {
  mapOfflineAuthorEntityToAuthorEntity,
  mapOfflineClientEntityToClientEntity,
  mapOfflineDocumentEntityToDocumentEntity,
  mapOfflineFieldEntityToFieldEntity,
  mapOfflineMarketEntityToMarketEntity,
} from './common/types/Mapper';
import { getCSSChangePer } from './common/util';
import Content from './components/content/content';
import CustomPopup from './components/dialog/dialog';
import Header from './components/header/header';
import { db } from './db/db';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import {
  fetchUserClientsDocuments,
  selectRows,
  setDocuments,
} from './redux/reducers/documentsDataSlice';
import { setShowTabSharePopup } from './redux/reducers/popupSlice';
import {
  selectAuthors,
  selectClients,
  selectFields,
  selectMarkets,
  setAuthors,
  setClients,
  setFields,
  setMarkets,
} from './redux/reducers/metadataSlice';
import {
  selectDefaultFontSize,
  selectFontSize,
  selectLineHeight,
  setCustomFontSize,
  setCustomTableFontSize,
  setCustomRowActiveHeight,
  setCustomRowInActiveHeight,
  setCustomCellPadding,
  setCustomLineHeight,
  selectTableFontSize,
} from './redux/reducers/uiSlice';
import { selectUserID } from './redux/reducers/userSlice';
import { sideEffects } from './redux/sideEffects';
import { selectIsAppInitialized, setInitialized } from './redux/reducers/appSlice';
import { setSelectedTab } from './redux/reducers/dashboardSlice';
import { ToastContainer } from 'react-toastify';
import FileDropContainer from './components/uploader/FileDropContainer';
import {
  selectUserActiveRowHeight,
  selectUserAppFontSize,
  selectUserCellPadding,
  selectUserInactiveRowHeight,
  selectUserLineHeight,
  selectUserTableFontSize,
} from './redux/reducers/settingsSlice';

function Wrapper() {
  const dispatch = useAppDispatch();
  const uiAppFontSize = useAppSelector(selectFontSize);
  const uiTableFontSize = useAppSelector(selectTableFontSize);
  const uiLineHeight = useAppSelector(selectLineHeight);
  const userId = useAppSelector(selectUserID);

  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const userAppFontSize = useAppSelector(selectUserAppFontSize);
  const userTableFontSize = useAppSelector(selectUserTableFontSize);
  const userCellPadding = useAppSelector(selectUserCellPadding);
  const userLineHeight = useAppSelector(selectUserLineHeight);
  const userActiveRowHeight = useAppSelector(selectUserActiveRowHeight);
  const userInActiveRowHeight = useAppSelector(selectUserInactiveRowHeight);

  const authors = useAppSelector(selectAuthors);
  const markets = useAppSelector(selectMarkets);
  const fields = useAppSelector(selectFields);
  const clients = useAppSelector(selectClients);
  const data = useAppSelector(selectRows);
  const defaultFontSize = useAppSelector(selectDefaultFontSize);

  //Initialize the App
  sideEffects();

  useEffect(() => {
    if (
      authors &&
      authors.length > 0 &&
      markets &&
      markets.length > 0 &&
      fields &&
      fields.length > 0 &&
      clients &&
      clients.length > 0
    ) {
      dispatch(setInitialized(true));
    }
  }, [clients, authors, fields, markets, data]);

  useLiveQuery(() => {
    if (!isAppInitialized) {
      db.authorValues.toArray().then((authors) => {
        dispatch(setAuthors(authors.map((author) => mapOfflineAuthorEntityToAuthorEntity(author))));
      });
    }
  }, [isAppInitialized]);

  useLiveQuery(() => {
    if (!isAppInitialized) {
      db.marketValues.toArray().then((markets) => {
        dispatch(setMarkets(markets.map((market) => mapOfflineMarketEntityToMarketEntity(market))));
      });
    }
  }, [isAppInitialized]);

  useLiveQuery(() => {
    if (!isAppInitialized) {
      db.clientValues.toArray().then((clients) => {
        dispatch(setClients(clients.map((client) => mapOfflineClientEntityToClientEntity(client))));
      });
    }
  }, [isAppInitialized]);

  useLiveQuery(() => {
    if (!isAppInitialized) {
      db.fieldValues.toArray().then((fields) => {
        dispatch(setFields(fields.map((field) => mapOfflineFieldEntityToFieldEntity(field))));
      });
    }
  }, [isAppInitialized]);

  useLiveQuery(() => {
    if (!isAppInitialized) {
      db.documents.toArray().then((documents) => {
        // Transform to the respective format and group them
        if (documents.length > 0) {
          let mappedDocuments = _.groupBy(
            documents.map((f) => mapOfflineDocumentEntityToDocumentEntity(f)),
            'client',
          );
          dispatch(setDocuments(mappedDocuments));
        }
      });
    }
  }, [isAppInitialized]);

  useEffect(() => {
    if (Number(userAppFontSize.value) > 0) {
      dispatch(setCustomFontSize(Number(userAppFontSize.value)));
    }
  }, [userAppFontSize]);

  useEffect(() => {
    if (Number(userTableFontSize.value) > 0) {
      dispatch(setCustomTableFontSize(Number(userTableFontSize.value)));
    }
  }, [userTableFontSize]);

  useEffect(() => {
    dispatch(setCustomCellPadding(Number(userCellPadding.value)));
  }, [userCellPadding]);

  useEffect(() => {
    if (Number(userLineHeight.value) > 0) {
      dispatch(setCustomLineHeight(Number(userLineHeight.value)));
    } else {
      dispatch(setCustomLineHeight(1.5));
    }
  }, [userLineHeight]);

  useEffect(() => {
    if (Number(userActiveRowHeight.value) > 0) {
      dispatch(setCustomRowActiveHeight(Number(userActiveRowHeight.value)));
    }
    if (Number(userInActiveRowHeight.value) > 0) {
      dispatch(setCustomRowInActiveHeight(Number(userInActiveRowHeight.value)));
    }
  }, [userActiveRowHeight, userInActiveRowHeight]);

  useEffect(() => {
    const changePer = getCSSChangePer(uiAppFontSize, defaultFontSize);
    // if (changePer) {
    //   dispatch(setFontPer(changePer));
    //   // let calHeight = getCellHeight(width, height);
    //   // if (calHeight != null) {
    //   //   calHeight = calHeight + calHeight * changePer;
    //   // }
    //   // dispatch(setCellHeight(calHeight));
    // }
  }, [uiAppFontSize]);

  useEffect(() => {
    // Handle the Documents
    if (!isAppInitialized && userId) {
      if (clients && clients.length > 0) {
        dispatch(
          fetchUserClientsDocuments({
            clientIds: clients.map((client) => client.id),
            userId: userId.toString(),
          }),
        );

        const sorted = [...clients]?.sort((c) => c.viewOrder);
        dispatch(setSelectedTab(sorted?.at(0)?.id));
      }
    }
  }, [clients, isAppInitialized]);

  const [loading] = useAxiosLoader();

  return (
    <section className='app-container'>
      <div className='flex-basis-column section-holder'>
        <CircleSpinnerOverlay
          loading={loading}
          color='#404040'
          overlayColor='rgba(255,253,255,0.9)'
          zIndex={5000}
        />
        <CustomPopup></CustomPopup>
        <section className='header-holder'>
          <Header></Header>
        </section>
        <Suspense>
          <section className='file-uploader-overlay'>
            <FileDropContainer />
          </section>
          <section className='content-holder'>
            <Content></Content>
          </section>
        </Suspense>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <ToastContainer />
    </section>
  );
}

export default Wrapper;
