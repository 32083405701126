import { FileUploader } from "react-drag-drop-files";
import { SUPPORTED_FILE_FORMATS } from "../../common/constants";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setName } from "../../redux/reducers/documentSlice";
import {
  selectDashboardLogoMetadata,
  selectUserIconMetadata,
} from "../../redux/reducers/uiSlice";
import { uploadDocument } from "../../redux/thunks";
import "./header.scss";
import { setUploader } from "../../redux/reducers/popupSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { getUpdatedCustomFontSize } from "../../common/util";
import { selectUserRole } from "../../redux/reducers/userSlice";
import { useEffect, useRef, useState } from "react";
import { selectReadOnlyApp } from "../../redux/reducers/appSlice";
import classNames from "classnames";

const Header = () => {
  const dispatch = useAppDispatch();
  const isReadOnly = useAppSelector(selectReadOnlyApp);
  const logoMetadata = useAppSelector(selectDashboardLogoMetadata);
  const userMetadata = useAppSelector(selectUserIconMetadata);
  const { user, logout } = useAuth0();
  const userRole =
    useAppSelector(selectUserRole) == "Landlord" ? "Landlord" : "Tenant";
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const openPopup = () => {
    dispatch(setUploader());
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    }
    if (showMenu) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);

  const logoutWithRedirect = () => {
    localStorage.setItem("logoutEvent", String(Date.now()));
    setShowMenu(!showMenu);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  window.addEventListener("storage", function (event) {
    if (event.key === "logoutEvent") {
      this.localStorage.removeItem("logoutEvent");
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  });

  const handleChange = (file: File) => {
    if (file !== undefined) {
      dispatch(setName(file.name));
      dispatch(uploadDocument(file)).then((d) => {
        openPopup();
      });
    }
  };
  const handleProfileMenu = () => {
    setShowMenu(true);
  };
  const customStyle = {
    justifyContent: "space-between",
    "--custom-CB-fontSize": getUpdatedCustomFontSize("--custom-CB-fontSize"),
    "--custom-upload-btn-fontSize": getUpdatedCustomFontSize(
      "--custom-upload-btn-fontSize"
    ),
    "--custom-user-details-fontSize": getUpdatedCustomFontSize(
      "--custom-user-details-fontSize"
    ),
  };
  return (
    // <div className="flex-fit-basis">
    <div className="flex-fit-basis" style={customStyle}>
      <div
        // style={{
        //   width: logoMetadata.widthPX,
        //   height: "var(--custom-page-header-height)",
        // }}
        className={classNames(
          "image-container",
          isReadOnly ? "visible-hidden" : "visibilty-not-hiddent"
        )}
      >
        <button
          className="flex flex-align-center header-button-v4 header-button-v4-text choose-button-text-size"
          onClick={openPopup}
        >
          Upload Status
        </button>
        <FileUploader
          classes="flex fit-container"
          types={SUPPORTED_FILE_FORMATS}
          handleChange={handleChange}
          name="file"
          maxSize={200}
          required={true}
          children={
            <div className="flex fit-container flex-align-center drag-continer-text drag-continer-text-size">
              <button className="header-button-v4 header-button-v4-text choose-button-text-size">
                Choose File
              </button>
              <label className="choose-button-text-size" htmlFor="fileUpload">
                or drag LOI into the Table
              </label>
            </div>
          }
        />
      </div>
      {/* className="image-container" */}
      <div style={{ display: "flex", alignItems: "center", marginLeft: "-7%" }}>
        <img
          className="cursor-default"
          width={logoMetadata.widthPX}
          height={logoMetadata.heightPX}
          src={logoMetadata.image}
        />
      </div>
      {/* d-actions-container */}

      <div
        className={classNames(
          isReadOnly ? "visible-hidden" : "visibilty-not-hiddent",
          "actions-container"
        )}
        onClick={handleProfileMenu}
        ref={menuRef}
      >
        <div className="flex user-container flex-align-center">
          <div className="flex-basis-column">
            <span className="user-details user-details-text-size">
              {user?.name}
            </span>
            {userRole && (
              <span className="user-details user-details-text-size author-tenant-color">
                {`${userRole} Representation`}
              </span>
            )}
          </div>
          <img
            className="user-profile-image"
            width={userMetadata.widthPX}
            height={userMetadata.heightPX}
            src={userMetadata.image}
          />
        </div>
      </div>

      {showMenu && (
        <div className="user-profile-menu">
          <div className="logout-menu" onClick={logoutWithRedirect}>
            Logout
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};

export default Header;
