import axios from "axios";
import { useEffect, useMemo, useState } from "react";

export const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 1200000,
});

export const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);

  const interceptors = useMemo(() => {
    const inc = () => setCounter((counter) => counter + 1);
    const dec = () => setCounter((counter) => counter - 1);
    const accessToken = localStorage.getItem('token');
   
    return {
      request: (config: any) => {
        config.headers.Authorization = `Bearer ${accessToken}`;
        if (
          config.url.includes("/status/apcomplete") ||
          config.url.includes("/status") ||
          config.url.includes("/client") ||
          config.url.includes("/fields/*") ||
          config.url.includes("/values") ||
          config.url.includes("/rows")
        ) {
          return config;
        } else {
          return inc(), config;
        }
      },
      response: (response: any) => {
        if (
          response.request.responseURL.includes("/status/apcomplete") ||
          response.request.responseURL.includes("/status") ||
          response.request.responseURL.includes("/client") ||
          response.request.responseURL.includes("/fields/*") ||
          response.request.responseURL.includes("/values") ||
          response.request.responseURL.includes("/rows")
        ) {
          return response;
        } else {
          return dec(), response;
        }
      },
      error: (error: any) => (dec(), Promise.reject(error)),
    };
  }, []);

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = AXIOS.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    // add response interceptors
    const resInterceptor = AXIOS.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      // remove all intercepts when done
      AXIOS.interceptors.request.eject(reqInterceptor);
      AXIOS.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};
