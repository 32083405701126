import { arrayMove } from "@dnd-kit/sortable";
import _ from "lodash";
import { Item, ItemParams } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { toast } from "react-toastify";
import {
  DELETE_FILTER_WARNING_ID,
  FILTER_SETTINGS_STATE,
  TAB_ACTIONS,
} from "../../../common/constants";
import { ClientEntity } from "../../../common/types/EntityTypes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  removeFilterFromDB,
  selectClients,
  selectInboxId,
  setClients,
  updateClientOrder,
} from "../../../redux/reducers/metadataSlice";
import {
  selectMenuTriggor,
  setMenu,
  setShowFilterDeletePopup,
  setShowTabSharePopup,
  selectModalOffset,
} from "../../../redux/reducers/popupSlice";

import "react-toastify/dist/ReactToastify.css";
import {
  getAllSharedDetails,
  getHashAndShareForFilter,
  getHashForFilter,
  selectSelectedFilterSharedEmailState,
  selectSelectedFilterSharedIsEnabledState,
  selectSelectedFilterSharedState,
  setDisabledAccessForSharedFilter,
  setEnabledAccessForSharedFilter,
  setFilterDialogState,
  setSelectedFilterSharedDisabledState,
  setSelectedFilterSharedEnabledState,
  setSelectedFilterSharedState,
  setSelectedTabFilterInfo,
} from "../../../redux/reducers/tabFilterSlice";
import { exportDocumentDataToExcel } from "../../../redux/reducers/documentsDataSlice";
import { selectUserID } from "../../../redux/reducers/userSlice";
import "./TabShareModal.scss";
import { ChangeEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import Toggle from "react-toggle";
import { useSelector } from "react-redux";

function TabShareModal() {
  const tabActionTriggor = useAppSelector(selectMenuTriggor);
  const dispatch = useAppDispatch();
  const [areEmailAddressValid, setEmailAddressValid] = useState(true);
  const selectedFilterSharedState = useSelector(
    selectSelectedFilterSharedState
  );
  const clients: ClientEntity[] = useAppSelector(selectClients);
  const currentClient = clients.find((c) => c.id == tabActionTriggor);
  const currentClientIndex = _.findIndex(clients, {
    id: tabActionTriggor,
  });
  const isInboxId = useAppSelector(selectInboxId) === tabActionTriggor;
  const userExternalId = useAppSelector(selectUserID);
  const [inputEmailAddress, setInputEmailAddress] = useState("");
  const [prevSharedEmailAddress, setPrevSharedEmailAddress] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const modalOffset = useAppSelector(selectModalOffset);
  const offsetLeft = modalOffset[0];
  const offsetTop = modalOffset[1];

  // dispatch(
  //   getAllSharedDetails({
  //     filterId: currentClient?.id,
  //     userId: userExternalId,
  //   })
  // ).then((resp) => {
  //   if (
  //     resp.payload &&
  //     resp.payload.data != undefined &&
  //     resp.payload.data != false
  //   ) {
  //     dispatch(setSelectedFilterSharedState(resp.payload.data));
  //     const emailIds = resp.payload.data.emailIds;
  //     setPrevSharedEmailAddress(emailIds ? emailIds : "");
  //     setIsEnabled(resp.payload.data.enabled);
  //   }
  // });

  const handleAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedFilterSharedState?.enabled) {
      dispatch(
        setDisabledAccessForSharedFilter({
          filterId: currentClient?.id,
          userId: userExternalId,
        })
      ).then((resp) => {
        if (resp.payload.data) dispatch(setSelectedFilterSharedDisabledState);
      });
    } else {
      dispatch(
        setEnabledAccessForSharedFilter({
          filterId: currentClient?.id,
          userId: userExternalId,
        })
      ).then((resp) => {
        if (resp.payload.data && selectedFilterSharedState)
          dispatch(setSelectedFilterSharedEnabledState);
      });
    }
  };

  const handleCopy = () => {
    dispatch(
      getHashForFilter({
        filterId: currentClient?.id,
        userId: userExternalId,
      })
    ).then((resp) => {
      const url =
        window.location.origin + "?readOnly&hash=" + resp.payload.data;
      navigator.clipboard.writeText(url);
      toast("A link to this tab has been copied to the clipboard!");
      dispatch(
        getAllSharedDetails({
          filterId: currentClient?.id,
          userId: userExternalId,
        })
      ).then((resp) => {
        if (
          resp.payload &&
          resp.payload.data != undefined &&
          resp.payload.data != null &&
          resp.payload.data != false
        ) {
          dispatch(setSelectedFilterSharedState(resp.payload.data[0]));
        } else {
          dispatch(setSelectedFilterSharedState(undefined));
        }
      });
    });
  };

  const handleShare = () => {
    if (areEmailAddressValid) {
      dispatch(
        getHashAndShareForFilter({
          filterId: currentClient?.id,
          userId: userExternalId,
          emaildIds: inputEmailAddress.split(","),
        })
      ).then((resp) => {
        const url =
          window.location.origin + "?readOnly&hash=" + resp.payload.data;
        navigator.clipboard.writeText(url);
        toast("A link to this tab has been copied to the clipboard! ");
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputEmailAddress(e.target.value);
    isEmailAddressValid();
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(setShowTabSharePopup(false));
  };

  const isEmailAddressValid = function () {
    if (inputEmailAddress.length > 0) {
      const emailAddr = inputEmailAddress.split(",");
      const validEmailAddr = emailAddr.filter((email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
      );
      setEmailAddressValid(emailAddr.length == validEmailAddr.length);
    }
  };
  const getPrevEmailAddress = function () {
    if (selectedFilterSharedState) {
      //   console.log(selectedFilterSharedState);
      //   console.log(selectedFilterSharedState.emailIds);
      //   console.log(
      //     selectedFilterSharedState.emailIds.map((val) => <span>{val}</span>)
      //   );
    }
    return "Name";
  };

  getPrevEmailAddress();

  return (
    <section
      className="tsm-container"
      style={{ top: offsetTop, left: offsetLeft }}
    >
      <div className="tsm-header">
        <div className={classNames("tsm-header-left")}>
          <span className="tsm-filter-shared">Share Tab</span>
          {selectedFilterSharedState && (
            <Toggle
              defaultChecked={selectedFilterSharedState.enabled}
              icons={false}
              onChange={handleAccess}
            />
          )}
          {/* <div
            className={classNames("tsm-header-right", {
              "tsm-filter-shared": !isEnabled,
            })}
            onClick={(e) => handleAccess()}
          >
            {isEnabled ? "Remove" : "Grant"} Access
          </div> */}
        </div>
        <div className={classNames("tsm-header-right")}>
          <div className={classNames("tsm-btn")} onClick={(e) => handleCopy()}>
            Copy Link
          </div>
        </div>
      </div>
      <hr className="custom-line" />

      <div className="tsm-input-container">
        <div className="tsm-notify-container">
          <span>Notify</span>
          <div className="tsm-action">
            <div className="tsm-action-left">
              <input
                type="email"
                autoFocus
                placeholder="Email address ( comma(,) separated )"
                value={inputEmailAddress}
                onChange={(e) => handleChange(e)}
                data-tooltip-id="email-validation"
                data-tooltip-content="Email Address Not Valid"
                data-tooltip-place="top"
              />
              {!areEmailAddressValid && (
                <Tooltip
                  id="email-validation"
                  data-tooltip-variant="error"
                  isOpen={areEmailAddressValid}
                />
              )}
            </div>
            <div className="tsm-action-right">
              {/* <div className="tsm-btn" onClick={(e) => handleCancel(e)}>
                Cancel
              </div> */}
              <div
                className={classNames(
                  inputEmailAddress && inputEmailAddress.length > 0
                    ? "tsm-btn-orange"
                    : "tsm-btn-disable",
                  "tsm-btn"
                )}
                onClick={(e) => handleShare()}
              >
                Notify
              </div>
            </div>
          </div>
        </div>
        <div className="tsm-past-container">
          <span>Past Notification</span>
          <div className="tsm-past">
            {selectedFilterSharedState &&
              selectedFilterSharedState?.emailIds
                ?.filter((item) => item != null)
                .map((item, index) => <span key={index}>{item}</span>)}
          </div>
        </div>
      </div>
    </section>
  );
}
export default TabShareModal;
